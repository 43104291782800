var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5cfd9d51d1884647c54975fc3546d92a7862adb7"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import {
  breadcrumbsIntegration,
  BrowserClient,
  dedupeIntegration,
  httpContextIntegration,
  linkedErrorsIntegration,
  defaultStackParser,
  browserTracingIntegration ,
  makeFetchTransport,
  setCurrentClient,
} from '@sentry/nextjs'

import { SupabaseClient } from '@supabase/supabase-js'
import { SupabaseIntegration } from '@supabase/sentry-js-integration'

import { SENTRY_CAPTURE_RATE, SENTRY_DSN } from 'sentry.constants.mjs'

// This creates a custom Sentry Client with minimal integrations
export const sentryClient = new BrowserClient({
  enabled: true,
  // Provide Sentry's Secret Key
  dsn: SENTRY_DSN,
  // Sentry's Error Transport Mechanism
  transport: makeFetchTransport,
  // Sentry's Stack Trace Parser
  stackParser: defaultStackParser,
  // All supported Integrations by us
  integrations: [
    dedupeIntegration(),
    httpContextIntegration(),
    breadcrumbsIntegration(),
    linkedErrorsIntegration(),
     new SupabaseIntegration(SupabaseClient, {
      tracing: true,
      breadcrumbs: true,
      errors: true,
    }),
    // @sentry/browser
    browserTracingIntegration ({
      shouldCreateSpanForRequest: (url) => {
        return !url.startsWith(`${process.env.NEXT_PUBLIC_SUPABASE_URL}/rest`)
      },
    })
  ],


  // We only want to allow ingestion from these pre-selected allowed URLs
  // Note that the vercel.app prefix is for our Pull Request Branch Previews
  allowUrls: [
    /^https:\/\/.+\.table-x\.com/,
    /^https:\/\/.+\.vercel\.app/,
    /http:\/\/localhost(:\d+)?|https:\/\/localhost(:\d+)?/,
  ],
  // Percentage of events to send to Sentry (1% of them) (for performance metrics)
  tracesSampleRate: SENTRY_CAPTURE_RATE,
  // Percentage of events to send to Sentry (1% of them) (for session replays)
  replaysSessionSampleRate: SENTRY_CAPTURE_RATE,
  // Percentage of events to send to Sentry (1% of them) (for session replays when error happens)

  replaysOnErrorSampleRate: 1.0,

  /* // Adds custom filtering before sending an Event to Sentry
beforeSend: (event, hint) => {
  // Attempts to grab the original Exception before any "magic" happens
  const exception = hint.originalException as Error;

  // We only want to capture Errors that have a Stack Trace and that are not Anonymous Errors
  return exception?.stack && !exception.stack.includes('<anonymous>')
    ? event
    : null;
}, */
})

// Attaches this Browser Client to Sentry
setCurrentClient(sentryClient);

// Loads this Dynamically to avoid adding this to the main bundle (initial load)
import('@sentry/nextjs').then(({ Replay, BrowserTracing }) => {
  sentryClient.addIntegration(new Replay({ maskAllText: false }))
  sentryClient.addIntegration(new BrowserTracing())

})
