import { ENUM_MODES, ENUM_PAGE_MODES } from '@/enums/Enums'
import { DURATION_VALUES, ENUM_ROLES, MODE_CONSTANTS, STATUS_CONSTANTS } from '@/constants/constants'
import { DropdownOptionT, ModifiedExtendedReservationT } from '@/types/globalTypes'
import { TranslationModule } from '@/i18n'

interface InitialStateProps {
    mode: ENUM_MODES
    reservation: ModifiedExtendedReservationT
    initialTimes?: {
        startTime: string
        endTime: string
    }
    date: string
    venueId: string
    pageMode: ENUM_PAGE_MODES
    userRoles: string[]
    preselectedTableId?: string
}

type TimePreferencesTextType = TranslationModule['TIME_PREFERENCES_TEXT']


const getCreateModeValues = (props: InitialStateProps) => {
    const isCustomerPage = props.pageMode === ENUM_PAGE_MODES.CUSTOMER

    const isTemporary = isCustomerPage ? true : props.reservation.is_temporary

    return {
        id: '',
        fullName: '',
        phone: '',
        email: '',  // Initialize as empty string
        people: 2,
        start_time: props.initialTimes?.startTime || '00:00',
        end_time: props.initialTimes?.endTime || '00:00',
        extra_information: '',
        status: STATUS_CONSTANTS.PENDING,
        is_walk_in: false,
        agreed_to_terms: false,
        is_active: false,
        is_temporary: isTemporary,
    }
}

const getEditModeValues = (reservation: ModifiedExtendedReservationT) => ({
    id: reservation.id,
    fullName: reservation.name_of_the_person,
    phone: reservation.phone_number,
    email: reservation.email || '',  // Handle potential null/undefined case
    people: reservation.people,
    start_time: reservation.start_time,
    end_time: reservation.end_time,
    extra_information: reservation.extra_information,
    status: reservation.status,
    is_walk_in: reservation.is_walk_in,
    agreed_to_terms: reservation.agreed_to_terms,
    is_active: reservation.is_active,
    is_temporary: reservation.is_temporary,
})

export const getDefaultRole = (userRoles: string[]) => {
    const isManager = userRoles.includes(ENUM_ROLES.MANAGER)
    const isHost = userRoles.includes(ENUM_ROLES.HOST)

    if (isManager) return ENUM_ROLES.MANAGER
    if (isHost) return ENUM_ROLES.HOST
    return ENUM_ROLES.CUSTOMER
}

export const getInitialStateValues = (props: InitialStateProps) => {

    const isCreateMode = props.mode === MODE_CONSTANTS.CREATE
    const modeSpecificValues = isCreateMode
        ? getCreateModeValues(props)
        : getEditModeValues(props.reservation)

    return {
        ...modeSpecificValues,
        date_of_reservation: props.date,
        venue_id: props.venueId,
        created_by_role: getDefaultRole(props.userRoles),
        table_id: props.preselectedTableId || '',
    }
}

export const generateTimeOptionsWithinRange = (
    start: Date,
    end: Date,
    stepMinutes: number
): DropdownOptionT[] => {
    const times: DropdownOptionT[] = []
    let current = new Date(start)
    const endTime = new Date(end)

    // Handle case where end time is on next day (after midnight)
    if (endTime < start) {
        endTime.setDate(endTime.getDate() + 1)
    }

    while (current <= endTime) {
        const hours = current.getHours()
        const minutes = current.getMinutes()
        const timeStr = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        times.push({
            value: timeStr,
            label: new Date(current).toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            })
        })

        current = new Date(current.getTime() + stepMinutes * 60000)
    }

    return times
}

export const getTimeOptions = (
    queryStartDate: Date,
    venueStartingHours: string,
    venueEndingHours: string,
    initialTime?: string
): DropdownOptionT[] => {
    if (initialTime) {
        const [startHours, startMinutes] = venueStartingHours.split(':').map(Number)
        const venueStart = new Date(queryStartDate)
        venueStart.setHours(startHours, startMinutes)

        const [selectedHours, selectedMinutes] = initialTime.split(':').map(Number)
        const selectedStart = new Date(queryStartDate)
        selectedStart.setHours(selectedHours, selectedMinutes)

        if (selectedStart >= venueStart && selectedHours < 24) {
            const maxStartTime = new Date(queryStartDate)
            maxStartTime.setHours(0, 0)
            return generateTimeOptionsWithinRange(selectedStart, maxStartTime, 15)
        }
    }

    const [startHours, startMinutes] = venueStartingHours.split(':').map(Number)
    const [endHours, endMinutes] = venueEndingHours.split(':').map(Number)

    const startDate = new Date(queryStartDate)
    startDate.setHours(startHours, startMinutes)

    const endDate = new Date(queryStartDate)
    endDate.setHours(endHours, endMinutes)

    // Handle case where end time is on next day
    if (endDate < startDate) {
        endDate.setDate(endDate.getDate() + 1)
    }

    return generateTimeOptionsWithinRange(startDate, endDate, 15)
}


export const getDurationOptions = (TIME_PREFERENCES_TEXT: TimePreferencesTextType) => [
    { value: DURATION_VALUES.ONE_HOUR, label: TIME_PREFERENCES_TEXT.ONE_HOUR },
    { value: DURATION_VALUES.TWO_HOURS, label: TIME_PREFERENCES_TEXT.TWO_HOURS },
    { value: DURATION_VALUES.THREE_HOURS, label: TIME_PREFERENCES_TEXT.THREE_HOURS },
    { value: DURATION_VALUES.FOUR_HOURS, label: TIME_PREFERENCES_TEXT.FOUR_HOURS },
    { value: DURATION_VALUES.FIVE_HOURS, label: TIME_PREFERENCES_TEXT.FIVE_HOURS },
    { value: DURATION_VALUES.UNTIL_CLOSE, label: TIME_PREFERENCES_TEXT.UNTIL_CLOSE },
]



export const normalizeTimeFormat = (time: string): string => {
    // If time already has seconds, return as is
    if (time.split(':').length === 3) return time;
    // Add :00 seconds if not present
    return `${time}:00`;
};
