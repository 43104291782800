import React from 'react'
import { Controller, useForm } from 'react-hook-form'

import BasicButton from '../BasicButton/BasicButton'
import DropdownComponent from '../DropdownComponent/DropdownComponent'
import InputComponent from '../InputComponent/InputComponent'
import { ChevronDownIcon, ClockIcon, UserIcon, UsersIcon } from '@heroicons/react/24/outline'
import { Clock4Icon, SearchIcon, UserPlusIcon } from 'lucide-react'

import { useGetTranslations } from '@/i18n'

import { cn } from '@/utils/cn'
import { handleNumericWholeNumberKeyDown } from '@/utils/functionUtils'
import { getDurationOptions } from '@/utils/reservationUtils'

import { TimePreferencesType } from '@/types/globalTypes'

interface TimePreferencesProps {
    timeOptions: { value?: string; label?: string }[]
    onSubmit: (data: TimePreferencesType) => void
    loading?: boolean
    selectedDate: Date
    selectedTimePreferences?: TimePreferencesType
}

const TimePreferences = ({
    timeOptions,
    onSubmit,
    loading,
    selectedTimePreferences,
    selectedDate,
}: TimePreferencesProps) => {
    const {
        CREATE_OR_EDIT_RESERVATION_TEXT,
        ACTION_BUTTONS_LABELS,
        VALIDATION_MESSAGES,
        TIME_PREFERENCES_TEXT,
    } = useGetTranslations()

    const DURATION_OPTIONS = getDurationOptions(TIME_PREFERENCES_TEXT)

    const {
        control,
        handleSubmit,
        formState: { errors },
        register,
        watch,
    } = useForm<TimePreferencesType>({
        defaultValues: {
            start_time: selectedTimePreferences?.start_time || '',
            duration: selectedTimePreferences?.duration || '',
            people: selectedTimePreferences?.people || 1,
            date: selectedDate,
        },
    })

    return (
        <div className="min-h-full px-6 rounded-lg card bg-base-100">
            <h2 className="my-8 text-xl font-bold text-center">
                {TIME_PREFERENCES_TEXT.SELECT_TIME_PREFERENCES}
            </h2>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
                <div className="space-y-2">
                    <label className="font-semibold">
                        {CREATE_OR_EDIT_RESERVATION_TEXT.INPUT_START_TIME_LABEL}
                    </label>
                    <div className="relative flex items-center w-full p-0.5 m-auto text-lg font-semibold transition-none transition-all rounded-lg input input-bordered dropdown dropdown-bottom z-[99999]">
                        <ClockIcon className="absolute w-5 h-5 left-3 top-3" />
                        <Controller
                            name="start_time"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <DropdownComponent
                                    options={timeOptions}
                                    selectedValue={value}
                                    handleSelect={(option) => onChange(option.value)}
                                    placeholder={CREATE_OR_EDIT_RESERVATION_TEXT.SELECT_TIME}
                                    containerClassName="w-full z-[99998] dropdown dropdown-bottom"
                                    className={cn('text-start indent-10 z-[9999]')}
                                    dropdownClassName="w-full shadow-lg bg-base-100 rounded-box border border-slate-600 dark:border-slate-100 !border-opacity-20 flex flex-col max-h-48 overflow absolute overflow-y-auto z-[9999] mt-3 animate-fade animate-duration-150"
                                />
                            )}
                        />
                        <ChevronDownIcon className="absolute w-5 h-5 right-3 top-3" />
                    </div>
                    {errors.start_time && (
                        <p className="text-sm text-red-500">
                            {VALIDATION_MESSAGES.START_TIME_IS_REQUIRED}
                        </p>
                    )}
                </div>
                <div className="space-y-2">
                    <label className="font-semibold">{TIME_PREFERENCES_TEXT.DURATION_LABEL}</label>
                    <div className="relative flex items-center w-full p-0.5 m-auto text-lg font-semibold transition-none transition-all rounded-lg input input-bordered dropdown dropdown-bottom z-[99998]">
                        <Clock4Icon className="absolute w-5 h-5 left-3 top-3" />
                        <Controller
                            name="duration"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                                const selectedOption = DURATION_OPTIONS.find(
                                    (option) => option.value === value
                                )
                                return (
                                    <DropdownComponent
                                        options={DURATION_OPTIONS}
                                        selectedValue={selectedOption?.label ?? ''}
                                        handleSelect={(option) => onChange(option.value)}
                                        placeholder={TIME_PREFERENCES_TEXT.SELECT_DURATION}
                                        containerClassName="w-full z-[99998] dropdown dropdown-bottom"
                                        className="text-start z-[9999] indent-10"
                                        dropdownClassName="w-full shadow-lg bg-base-100 rounded-box border border-slate-600 dark:border-slate-100 !border-opacity-20 flex flex-col max-h-28 overflow absolute overflow-y-auto z-[9999] mt-3 animate-fade animate-duration-150"
                                        showDropdownLabel={true}
                                        selectedValueClass="block"
                                    />
                                )
                            }}
                        />
                        <ChevronDownIcon className="absolute w-5 h-5 right-3 top-3" />
                    </div>
                    {errors.duration && (
                        <p className="text-sm text-red-500">
                            {VALIDATION_MESSAGES.DURATION_IS_REQUIRED}
                        </p>
                    )}
                </div>

                {/* People input */}
                <div className="w-full m-auto">
                    <label className="label">
                        <span className="font-semibold">
                            {CREATE_OR_EDIT_RESERVATION_TEXT.INPUT_PEOPLE_PLACEHOLDER}
                        </span>
                    </label>
                    <div className="relative flex items-center p-0 input input-bordered">
                        <UserPlusIcon
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="absolute w-6 h-6 ml-3"
                        />
                        <InputComponent
                            type="number"
                            required
                            name="people"
                            {...register('people', { required: true, min: 1, max: 25 })}
                            onKeyDown={handleNumericWholeNumberKeyDown}
                            className="w-full pl-5 indent-5 focus:outline-none"
                            min={1}
                            max={25}
                        />
                    </div>
                    {errors?.people && <p className="text-red-500">{errors.people.message}</p>}
                </div>

                <div className="flex justify-center mt-5">
                    <BasicButton
                        type="submit"
                        disabled={loading}
                        isLoading={loading}
                        className={cn('btn btn-success min-w-24', {
                            'cursor-not-allowed': loading,
                        })}
                    >
                        <SearchIcon className="w-5 h-5" />
                        {ACTION_BUTTONS_LABELS.SEARCH}
                    </BasicButton>
                </div>
            </form>
        </div>
    )
}

export default TimePreferences
